<!-- 订单来源投诉处理弹窗 -->
<template>
  <div>
    <el-dialog
        width="50%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        custom-class="ele-dialog-form"
        :title="isUpdate?'投诉处理':'投诉处理'"
        @update:visible="updateVisible">

      <el-form
          ref="form"
          :model="form"
          label-width="120px">
        <!-- 选项 -->
        <el-row>
          <div class="xuanxianglist">
            <el-radio-group v-model="telephone_type" @change="open">
              <el-radio :label="item.telephone_type" v-for="(item,index) in list">{{item.name}}</el-radio>
            </el-radio-group>
          </div>
        </el-row>
        <el-form-item label="">
          <el-button>投诉转发司机</el-button>
          <el-button>投诉转发服务商</el-button>
        </el-form-item>
        <el-form-item label="客户投诉时间：">
          <span>2023-03-18 13:21:00</span>
        </el-form-item>
        <el-form-item label="客户投诉原因：">
          <span>客户对救援师傅服务态度不好投诉</span>
          <el-button type="text" class="huifustyle" v-if="isedit == false" @click="huifu()">回复</el-button>
          <el-button type="text" class="huifustyle" v-if="isedit == true" @click="quxiao()">取消</el-button>
          <el-button type="text" class="huifustyle" v-if="isedit == true" @click="queren()">确认</el-button>
          <el-input
            v-if="isedit == true"
            clearable
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="form.textarea2">
          </el-input>
        </el-form-item>

        <el-form-item label="处理投诉结果：">
          <el-form-item label="是否同意撤销投诉：" label-width="130px">
            <el-radio v-model="radio2" label="1">是</el-radio>
            <el-radio v-model="radio2" label="2">否</el-radio>
          </el-form-item>
          <el-form-item label="投诉是否属实：" label-width="130px">
            <el-radio v-model="radio3" label="1">是</el-radio>
            <el-radio v-model="radio3" label="2">否</el-radio>
          </el-form-item>
          <el-form-item label="客户满意度：" label-width="130px">
            <el-radio v-model="radio4" label="1">非常满意</el-radio>
            <el-radio v-model="radio4" label="2">满意</el-radio>
            <el-radio v-model="radio4" label="3">不满意</el-radio>
          </el-form-item>
        </el-form-item>

        <el-form-item label="投诉处理说明：">
          <el-input
            clearable
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 8}"
            placeholder="请输入内容"
            v-model="form.textarea">
          </el-input>
        </el-form-item>

        <el-form-item label="上传图片">
          <el-upload
            :action="action"
            :headers="headers"
            accept=".jpg, .png, jpeg"
            :on-success="successUpload"
            :limit="9"
            :file-list="fileList"
            :on-change="change"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>

        <el-form-item label="上传文档">
          <el-upload
            class="upload-demo"
            :action="action"
            :headers="headers"
            :on-preview="handlePreview"
            :on-remove="handleRemove1"
            :before-remove="beforeRemove"
            :on-success="successUpload1"
            multiple
            :limit="3"
            :on-exceed="handleExceed"
            :file-list="fileList">
            <div class="shangchuan" style="margin-left: 20px">
              <el-button class="custom-button">
                <i class="el-icon-shangchuan" />
                <span style="vertical-align: middle">点击上传</span>
              </el-button>
            </div>
            <div slot="tip" class="el-upload__tip" style="margin-left: 20px;"></div>
          </el-upload>
        </el-form-item>

      </el-form>

      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            v-auths="[`${$config.uniquePrefix}orderModule:info:followup`]"
            @click="save">确定
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="拨打电话"
        center
        :visible.sync="drawer"
        :show-close="false"
        width="30%"
        :before-close="handleClose">
      <div class="paddingbianju">
        <el-radio-group v-model="radio">
          <div class="xiabianju">
            <el-radio :label="1">小明 13103000257</el-radio>
          </div>
          <div class="xiabianju">
            <el-radio :label="2">小红 13103000257</el-radio>
          </div>
          <div class="xiabianju">
            <el-radio :label="3">小黑 13103000257</el-radio>
          </div>
        </el-radio-group>
      </div>
      <div slot="footer">
        <el-button class="rightmargin" @click="drawer = false">
          取消
        </el-button>
        <el-button type="primary" @click="queding" class="rightmargin">
          确定
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies, setCookies} from '@/utils/cookies'
import Config from '@/config'
// 引入的接口
import {order_follow} from '@/api/orderModule'
// 引入的接口
import {get_tel_no} from '@/api/phone'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      //司机相关
      list:[
        {
          name:'拨打车主电话',
          telephone_type:'owner'
        },
        {
          name:'拨打订单来源客户电话',
          telephone_type:'upstream'
        },
        {
          name:'拨打司机电话',
          telephone_type:'technician'
        },
        {
          name:'拨打服务商电话',
          telephone_type:'facilitator'
        }
      ],
      isShow:0,

      telephone_type:'owner',

      // 上传图片
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList:[],
      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      // 电话抽屉
      drawer: false,
      direction: 'rtl',

      radio:1,

      //回复
      isedit:false,
      showhuifu:false,

      radio2:'',
      radio3:'',
      radio4:'',

    };
  },


  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.form.remark = '';
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
  },

  methods: {
    // 上传图片
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    change(){
      // 上传成功后，隐藏上传按钮
      this.upload_btn = true;
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file);
      console.log(fileList)
      this.dialogImageUrl = file.data.urls;
    },


    //上传文档
    handleRemove1(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    //上传成功
    successUpload1(file,fileList){
      console.log(file);
      console.log(fileList)
      // 上传成功后的列表
      // this.form.agreement.push(file.data.urls);
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
            this.loading = true;
            this.updateVisible(false);
            this.$emit('done');
            this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      this.telephone_type = item.telephone_type;
      if(item.name == '拨打客户电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打订单来源客户电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打司机电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhuaactive.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhua.png');
      }else if(item.name == '拨打服务商电话'){
        this.list[0].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[1].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[2].img = require('../../../../assets/images/home/genjindianhua.png');
        this.list[3].img = require('../../../../assets/images/home/genjindianhuaactive.png');
      }

    },


    open(){
      this.drawer = true;
    },

    handleClose() {
      this.drawer = false;
    },
    queding(){
      this.tel();
    },

    // 点击拨打电话
    tel(phone,tel_type){
      get_tel_no().then(res => {
        if(res.data.is_exist == 0){

          // 显示拨打电话弹窗
          let data = {
            showPhone: true
          }
          this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
          // 要拨打的手机号
          let dianhua = {
            phone: phone
          }
          this.$addStorageEvent(1, "phone", JSON.stringify(dianhua))

          // 获取 订单号id
          let orderid = {
            orderid: this.data.id,
          }
          this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

          // 获取呼出类型
          let teltype = {
            tel_type: tel_type
          }
          this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
        }else {
          this.$message.error('暂无坐席')
        }
      })
    },

    huifu(){
      this.isedit = true;
    },
    quxiao(){
      this.isedit = false;
    },
    //点击确认
    queren(){
      this.isedit = false;
    },

  }
}
</script>

<style scoped lang="scss">
.xuanxianglist{
  margin-bottom: 20px;
  .xuanxianglistactive{
    width: 23%;
    cursor: pointer;
    background: #e8e8e8;
    border: 1px solid #dadada;
    border-radius: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #7b7b7b;
    text-align: center;
    margin-top: 0;
    margin-right: 10px;
    display: inline-block;
  }
  .xuanxianglistactive1{
    background: #FF9B05;
    color: #FFFFFF;
    border-color: #FF9B05;
  }

  //最后一个
  .xuanxianglistactive:last-child{
    margin-right: 0;
  }

}

.paddingbianju{
  //padding: 20px;
}

.phoneicon{
  width: 16px;height: 16px;margin-left: 10px;
}
.xiabianju{
  margin: 20px;
}
.rightmargin{
  margin-right: 20px;
}


.tianjiagenjin{
  width: 670px;
  font-size: 18px;
}

.topmargin{
  margin-top: 20px;
}
.genjinfont{
  width: 5px;height: 16px;background: #a3a4b2;display: inline-block;
}
.genjinfont1{
  font-size: 16px;font-weight: 500;color: #3f4155;margin-left: 10px;
}
.leftmargin{
  margin-left: 20px;
}
.leftmargin1{
  margin-left: 15px;
}

.huifustyle{
  margin-left: 20px;
}
</style>
